import { Injectable } from '@angular/core';
import { getQueryParams, Go } from '@frontend/common/ph-router-store';
import { Store } from '@ngrx/store';
import { withLatestFrom } from 'rxjs/operators';
import { DisplayBoardExpectedRouteService } from './display-board-expected-route.service';

@Injectable({ providedIn: 'root' })
export class DisplayBoardTypeRedirectService {
  constructor(
    private store: Store,
    private displayBoardExpectedRouteService: DisplayBoardExpectedRouteService
  ) {
    this.displayBoardExpectedRouteService.expectedRoute$
      .pipe(withLatestFrom(this.store.select(getQueryParams)))
      .subscribe(([expectedRoute, queryParams]) => {
        this.store.dispatch(new Go({ path: [expectedRoute], extras: { queryParams } }));
      });
  }
}
