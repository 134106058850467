import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { GraphQlService } from './graphql.service';

@Injectable()
export class GraphQlGuard implements CanActivate {
  constructor(private graphQlSrv: GraphQlService) {}

  canActivate(): Observable<boolean> {
    return this.graphQlSrv.isReady$;
  }
}
