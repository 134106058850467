import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CorrelationIdService } from '@frontend/common/correlation-id';
import { PhAuthService } from '@frontend/common/ph-auth';
import { PhConfigLoaderService } from '@frontend/common/ph-config-loader';
import { Observable } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private inj: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const exceptions = ['id', 'config.json', 'version', 'code', 'token'];
    for (const exception of exceptions) {
      const part = req.url.split('/').slice(-1)[0].split('?')[0];
      const assetsException =
        req.url.includes('assets') &&
        req.method === 'GET' &&
        !req.url.includes('assets/export');
      const assetTypesException =
        req.url.includes('asset-types') &&
        req.method === 'GET' &&
        !req.url.includes('asset-types/export');
      const graphqlException = req.url.includes('graphql');
      if (
        part === exception ||
        assetsException ||
        assetTypesException ||
        graphqlException
      ) {
        return next.handle(req);
      }
    }
    const config = this.inj.get(PhConfigLoaderService);
    const correlationIdService = this.inj.get(CorrelationIdService);
    if (req.body && req.body.correlation_id) {
      correlationIdService.add(req.body.correlation_id, req.body);
      if (req.body.items) {
        req.body.items.forEach((item) =>
          correlationIdService.add(item.correlation_id, item)
        );
      }
    }
    const phAuthSrv = this.inj.get(PhAuthService);
    return phAuthSrv.token$.pipe(
      first(),
      mergeMap((token) => {
        const baseUrlKey =
          req.url.includes('sites/hierarchy-export') ||
          req.url.includes('locations/export')
            ? 'CA_GRAPHQL_REST_URL'
            : req.url.startsWith('securable')
            ? 'APP_SECURABLE_API_URL'
            : 'APP_API_URL';
        let update: any = {
          url: config.getConfig(baseUrlKey) + req.url,
        };
        if (token) {
          update = {
            ...update,
            setHeaders: {
              Authorization: req.headers.get('Authorization') || `Bearer ${token}`,
            },
          };
        }
        return next.handle(req.clone(update));
      })
    );
  }
}
