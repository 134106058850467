import { templateReplacer, TemplateReplacerMap } from './template-replacer';
import { INTERNALS } from './token-internals';
import { TRANSLATIONS } from './token-translations';

const INTERNAL_VS_TRANSLATION: TemplateReplacerMap = [
  [INTERNALS.workflowName, TRANSLATIONS.workflowName],
  [INTERNALS.phaseName, TRANSLATIONS.phaseName],
  [INTERNALS.supplementaryAnnouncement, TRANSLATIONS.supplementaryAnnouncement],
  [INTERNALS.stationName, TRANSLATIONS.stationName],
  [INTERNALS.tagLabel, TRANSLATIONS.tagLabel],
  [INTERNALS.badgeLabel, TRANSLATIONS.badgeLabel],
  [INTERNALS.patientFirstName, TRANSLATIONS.patientFirstName],
  [INTERNALS.date, TRANSLATIONS.date],
  [INTERNALS.time, TRANSLATIONS.time],
  [INTERNALS.organizationAlias, TRANSLATIONS.organizationAlias],
];

const TRANSLATION_VS_INTERNAL: TemplateReplacerMap = INTERNAL_VS_TRANSLATION.map(
  ([TRANSLATION, INTERNAL]) => [INTERNAL, TRANSLATION]
);

export function localizeTokens(template: string): string {
  return templateReplacer(template, INTERNAL_VS_TRANSLATION);
}

export function deLocalizeTokens(template: string): string {
  return templateReplacer(template, TRANSLATION_VS_INTERNAL);
}
