class ErrorProcessor {
  humanizeStr(str: string): string {
    return str.replace(/_/g, ' ');
  }

  humanize(str, ...params): string {
    if (params.some((key) => !key)) {
      throw new Error($localize`Unexpected error humanizing an expected error.`);
    }
    return str.reduce(
      (acc, curr, i) =>
        [acc, this.humanizeStr(curr), params[i] ?? '']
          .join('')
          .replace(/^\w/, (c) => c.toUpperCase()),
      ''
    );
  }

  getEntityName(entity) {
    switch (entity) {
      case 'tag':
        return $localize`Badge | Tag`;
      case 'asset':
        return $localize`Tag`;
      default:
        return $localize`Badge`;
    }
  }

  getCustomFieldName(entity) {
    switch (entity) {
      case 'tag':
        return $localize`value`;
      case 'asset':
        return null;
      default:
        return $localize`badge_value`;
    }
  }

  handleDuplicateKeyError(ws) {
    const { entity, payload, field } = ws;
    if (field === 'asset_type_id_location_id') {
      return $localize`These PAR levels already exists.`;
    }
    const val = payload?.[field];
    let newEntity = entity;
    let newField = field;
    if (field === 'tag_value') {
      newEntity = this.getEntityName(entity);
      newField = this.getCustomFieldName(entity) || field;
    }
    if (field === 'label' && entity === 'tag') {
      newEntity = this.getEntityName(entity);
    }
    return this
      .humanize`${$localize`${newEntity} with ${newField} \"${val}\" already exists.`}`;
  }

  handleEntityNotFoundError(ws) {
    const { entity, payload } = ws;
    const id = payload?.id;
    const newEntity = entity === 'tag' ? $localize`Badge | Tag` : entity;
    return this.humanize`${$localize`${newEntity} with id ${id} was not found.`}`;
  }

  handleSensorExistsError(ws) {
    const { sensor, location } = ws;
    const loc = location?.alias_name || location?.name;
    const sensorValue = sensor?.sensor_value;
    return this.humanize`${$localize`Sensor ${sensorValue} already exists in ${loc}.`}`;
  }

  handleNoMasterEntityFoundError(ws) {
    const { masterEntity, entity, payload, field } = ws;
    const val = payload?.[field];
    let newEntity = masterEntity;
    let newField = field;
    if (field === 'tag_value') {
      newEntity = this.getEntityName(entity);
      newField = this.getCustomFieldName(entity) || field;
    }
    return this
      .humanize`${$localize`${newEntity} with ${newField} \"${val}\" not found.`}`;
  }

  handleIsNotValid(ws) {
    const { field } = ws;
    const newField = field === 'tag_value' ? $localize`value` : field;
    return this.humanize`${$localize`${newField} is not valid.`}`;
  }

  handleIsRequired(ws) {
    const { field } = ws;
    const newField = field === 'tag_value' ? $localize`value` : field;
    return this.humanize`${$localize`${newField} is required value.`}`;
  }

  handleMaxLength(ws) {
    const { field, limit } = ws;
    const newField = field === 'tag_value' ? $localize`value` : field;
    return this.humanize`${$localize`${newField} exceeds ${limit} characters.`}`;
  }

  handleTagIsInUseError(ws) {
    const { entity } = ws;
    const newEntity = this.getEntityName(entity);
    return $localize`This ${newEntity} is currently in use.`;
  }

  process<T>(ws: T & { errorCode?: string }): T & { message: string } {
    let message = $localize`Internal server error.`;
    try {
      switch (ws.errorCode) {
        case 'DUPLICATE_KEY':
          message = this.handleDuplicateKeyError(ws);
          break;
        case 'ENTITY_NOT_FOUND':
          message = this.handleEntityNotFoundError(ws);
          break;
        case 'NO_MASTER_ENTITY_FOUND':
          message = this.handleNoMasterEntityFoundError(ws);
          break;
        case 'TAG_IS_IN_USE':
          message = this.handleTagIsInUseError(ws);
          break;
        case 'SENSOR_IS_IN_USE':
          message = this.handleSensorExistsError(ws);
          break;
        case 'ASSET_TYPE_IS_IN_USE':
          message = $localize`Can't complete action: Asset type is in use.`;
          break;
        case 'IS_NOT_VALID':
          message = this.handleIsNotValid(ws);
          break;
        case 'IS_REQUIRED':
          message = this.handleIsRequired(ws);
          break;
        case 'MAX_LENGTH':
          message = this.handleMaxLength(ws);
          break;
        case 'USER_LEVEL_IS_NOT_VALID':
          message = $localize`You do not have permissions to set this user level`;
          break;
        case 'WORKFLOW_HAS_ACTIVE_PATIENTS':
          message = $localize`Action can’t be completed due to active patients in the workflow.`;
          break;
        case 'TAG_IS_NON_REPORTING':
          message = $localize`This Tag is non-reporting. Please select a different one.`;
          break;
        case 'TAG_HAS_LOW_BATTERY':
          message = $localize`This Tag has a low battery. Please select a different one.`;
          break;
        case 'AUTH0_API_USER_CREATE_CONFLICT':
          message = $localize`Email is already in use.`;
          break;
      }
    } catch (err) {}
    return { ...ws, message };
  }
}

export const errorProcessor = new ErrorProcessor();
