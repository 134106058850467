export const TRANSLATIONS = {
  workflowName: $localize`[workflow_name]`,
  phaseName: $localize`[phase_name]`,
  supplementaryAnnouncement: $localize`[supplementary_announcement]`,
  stationName: $localize`[station_name]`,
  tagLabel: $localize`[tag_label]`,
  badgeLabel: $localize`[badge_label]`,
  patientFirstName: $localize`[patient_first_name]`,
  date: $localize`[date]`,
  time: $localize`[time]`,
  organizationAlias: $localize`[organization_alias]`,
};
