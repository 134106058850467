import {
  DisplayBoardConfigActions,
  DisplayBoardConfigActionTypes,
} from './display-board-config.actions';
import { DisplayBoardConfig } from './display-board-config.model';

export const featureKey = 'displayBoardConfig';

export interface State {
  config: DisplayBoardConfig;
}

const initialState: State = {
  config: null,
};

export function reducer(state = initialState, action: DisplayBoardConfigActions): State {
  switch (action.type) {
    case DisplayBoardConfigActionTypes.Load: {
      return { config: action.payload };
    }
    default:
      return state;
  }
}
