import gql from 'graphql-tag';
import { Timezone } from './timezone.model';

export interface TimezonesQuery {
  timezones: Timezone[];
}

export const TIMEZONES_QUERY = gql(`
query TIMEZONES_QUERY($sorting: [TimezonesResolver_Timezones_SortingInputType!]){
  timezones(order_by: $sorting) {
    id
  }
}
`);
