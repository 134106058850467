import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AUTH_FLOW_DEVICE_ROUTES,
  AUTH_FLOW_LOGOUT_ROUTES,
  AUTH_FLOW_SDK_ROUTES,
  PhAuthGuard,
  ScopesGuard,
} from '@frontend/common/ph-auth';
import { DisplayBoardGuard } from './display-board-config.guard';
import { DISPLAY_BOARD_ROUTES } from './display-board-routes';
import { DISPLAY_BOARD_TYPES } from './display-board-types';
import { GraphQlGuard } from './graphql.guard';

const routes: Routes = [
  ...AUTH_FLOW_LOGOUT_ROUTES,
  ...AUTH_FLOW_SDK_ROUTES,
  ...AUTH_FLOW_DEVICE_ROUTES,
  {
    path: '',
    data: {
      requiredScopes: ['access:display-board'],
    },
    canActivate: [PhAuthGuard, ScopesGuard, GraphQlGuard],
    children: [
      {
        path: '',
        canActivate: [DisplayBoardGuard],
        children: [
          {
            path: DISPLAY_BOARD_ROUTES[DISPLAY_BOARD_TYPES.WORKFLOW],
            loadChildren: () =>
              import('@frontend/display-board/feature/workflow').then(
                (m) => m.DisplayBoardFeatureWorkflowModule
              ),
          },
          {
            path: DISPLAY_BOARD_ROUTES[DISPLAY_BOARD_TYPES.FAMILY],
            loadChildren: () =>
              import('@frontend/display-board/feature/family').then(
                (m) => m.DisplayBoardFeatureFamilyModule
              ),
          },
          {
            path: DISPLAY_BOARD_ROUTES[DISPLAY_BOARD_TYPES.PATIENT],
            loadChildren: () =>
              import('@frontend/display-board/feature/patient').then(
                (m) => m.DisplayBoardFeaturePatientModule
              ),
          },
          {
            path: DISPLAY_BOARD_ROUTES[DISPLAY_BOARD_TYPES.ASSETS_PAR_LEVEL],
            loadChildren: () =>
              import('@frontend/display-board/feature/assets-par-level').then(
                (m) => m.DisplayBoardFeatureAssetsParLevelModule
              ),
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
