import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { PhConfigLoaderFactoryModel } from './ph-config-loader-factory.model';
import { PhConfigLoaderService } from './ph-config-loader.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [PhConfigLoaderService],
})
export class PhConfigLoaderModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: PhConfigLoaderModule
  ) {
    if (parentModule) {
      throw new Error(
        'PhConfigLoaderModule is already loaded. Import it in the main module only'
      );
    }
  }

  static forRoot(useClass): ModuleWithProviders<PhConfigLoaderModule> {
    return {
      ngModule: PhConfigLoaderModule,
      providers: [{ provide: PhConfigLoaderFactoryModel, useClass }],
    };
  }
}
