export class ExpectedError {
  public expectedError: string;
  public stack: Error;

  constructor(message: string) {
    this.expectedError = message;
    this.stack = new Error(message);
  }
}

// DEPRECATED
export function throwExpectedError(message: string) {
  throw new ExpectedError(message);
}
