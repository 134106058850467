import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthFlows, PhAuthService } from '@frontend/common/ph-auth';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class AppLoadingService {
  isLoading$ = combineLatest([this._isAuthenticated$, this._authFlow$]).pipe(
    map(_calculateLoadingState),
    shareReplay(1)
  );

  constructor(private phAuthSrv: PhAuthService, private activatedRoute: ActivatedRoute) {}

  get _isAuthenticated$() {
    return this.phAuthSrv.isAuthenticated$;
  }

  get _authFlow$(): Observable<AuthFlows> {
    return this.activatedRoute.queryParams.pipe(map((params) => params.auth_flow));
  }
}

function _calculateLoadingState([isAuthenticated, authFlow]: [boolean, AuthFlows]) {
  if (authFlow === AuthFlows.DEVICE) {
    return false;
  }
  if (isAuthenticated) {
    return false;
  }
  return true;
}
