import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Injectable } from '@angular/core';

export const routerReducerInitialState = {
  state: {
    url: window.location.pathname,
    params: {},
    queryParams: {},
  },
  navigationId: 0,
};

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export const getRouterState = createFeatureSelector<
  fromRouter.RouterReducerState<RouterStateUrl>
>('routerReducer');

export const getUrl = createSelector(
  getRouterState,
  (router: fromRouter.RouterReducerState<RouterStateUrl>) => router.state.url
);
export const getParams = createSelector(
  getRouterState,
  (router: fromRouter.RouterReducerState<RouterStateUrl>) => router.state.params
);
export const getQueryParams = createSelector(
  getRouterState,
  (router: fromRouter.RouterReducerState<RouterStateUrl>) => router.state.queryParams
);

@Injectable()
export class CustomSerializer
  implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;
    let state: ActivatedRouteSnapshot = routerState.root;
    let params = { ...state.params };
    while (state.firstChild) {
      state = state.firstChild;
      params = { ...params, ...state.params };
    }
    params = Object.keys(params).reduce(
      (acc, key) => ({
        ...acc,
        ...{
          [key]: castIfInt(params[key]),
        },
      }),
      {}
    );
    return { url, queryParams, params };
  }
}

function castIfInt(val) {
  const parsed = parseInt(val, 10);
  return isNaN(parsed) ? val : parsed;
}
