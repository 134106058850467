import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { getUrl } from '@frontend/common/ph-router-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { DisplayBoardConfigService } from './display-board-config.service';
import { DisplayBoardExpectedRouteService } from './display-board-expected-route.service';
import { DisplayBoardTypeRedirectService } from './display-board-type-redirect.service';

@Injectable()
export class DisplayBoardGuard implements CanActivate {
  constructor(
    public displayBoardConfigService: DisplayBoardConfigService,
    public displayBoardTypeRedirectService: DisplayBoardTypeRedirectService,
    private displayBoardExpectedRouteService: DisplayBoardExpectedRouteService,
    private store: Store
  ) {}

  canActivate(): Observable<UrlTree | boolean> {
    return this.displayBoardExpectedRouteService.expectedRoute$.pipe(
      mergeMap((expectedRoute) =>
        this.store.select(getUrl).pipe(map((url) => url.indexOf(expectedRoute) > -1))
      )
    );
  }
}
