import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { IdToken } from '@auth0/auth0-spa-js';
import { BehaviorSubject, Observable, ReplaySubject, timer } from 'rxjs';
import { filter, first, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

export type PhAuthServiceConfig = Observable<{
  APP_API_URL: string;
  AUTH_DOMAIN: string;
  AUTH_AUDIENCE: string;
  AUTH_ORG_ID: string;
  AUTH_DEVICE_CLIENT_ID?: string;
  REFRESH_TOKEN_IN_ADVANCE_SEC: number;
  AUTH_LOGOUT_CALLBACK_URL: string;
  LOCALE: string;
}>;

export const PH_AUTH_SERVICE_CONFIG = new InjectionToken<PhAuthServiceConfig>(
  'PH_AUTH_SERVICE_CONFIG'
);

export enum AuthFlows {
  SDK = '0',
  DEVICE = '1',
}

@Injectable()
export class PhAuthService {
  private _token$: ReplaySubject<string> = new ReplaySubject(1);
  private _jwt$: ReplaySubject<IdToken> = new ReplaySubject(1);
  private _scopes$: ReplaySubject<string[]> = new ReplaySubject(1);
  private _inAdvance$: ReplaySubject<number> = new ReplaySubject(1);
  private _requestUpdate$: ReplaySubject<number> = new ReplaySubject(1);
  private _isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private authSrv: AuthService,
    @Inject(PH_AUTH_SERVICE_CONFIG)
    private phAuthServiceConfig: PhAuthServiceConfig,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.phAuthServiceConfig
      .pipe(map((config) => config.REFRESH_TOKEN_IN_ADVANCE_SEC))
      .subscribe(this._inAdvance$);

    this._jwt$
      .pipe(
        map((jwt) => jwt.exp),
        withLatestFrom(this._inAdvance$),
        map(
          ([expirationTime, inAdvance]) =>
            expirationTime * 1000 - new Date().getTime() - inAdvance * 1000
        ),
        switchMap((refreshInMs) => timer(refreshInMs))
      )
      .subscribe(this._requestUpdate$);

    this._jwt$.pipe(map((jwt) => jwt.scope.split(' '))).subscribe(this._scopes$);

    this._token$
      .pipe(map((token) => JSON.parse(atob(token.split('.')[1]))))
      .subscribe(this._jwt$);

    this._token$.pipe(map((token) => !!token)).subscribe(this._isAuthenticated$);
  }

  get token$() {
    return this._token$.asObservable();
  }

  get jwt$() {
    return this._jwt$.asObservable();
  }

  get scopes$() {
    return this._scopes$.asObservable();
  }

  get requestUpdate$() {
    return this._requestUpdate$.asObservable();
  }

  get isAuthenticated$() {
    return this._isAuthenticated$.asObservable();
  }

  get whenAuthenticated$() {
    return this._isAuthenticated$.pipe(
      filter((isAuthenticated) => isAuthenticated),
      first()
    );
  }

  get user$() {
    return this.authSrv.isAuthenticated$.pipe(
      filter((sdkAuth) => !!sdkAuth),
      mergeMap(() => this.authSrv.user$)
    );
  }

  setAuthFlow(flow: AuthFlows): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { auth_flow: flow.toString() },
      queryParamsHandling: 'merge',
      skipLocationChange: true,
    });
  }

  setToken(newToken): void {
    this._token$.next(newToken);
  }

  getAccessTokenSilently() {
    return this.phAuthServiceConfig.pipe(
      switchMap((config) => {
        const organization = config.AUTH_ORG_ID;
        const [ui_locales] = config.LOCALE.split('-');
        return this.authSrv.getAccessTokenSilently({
          authorizationParams: { organization, ui_locales },
        });
      })
    );
  }

  loginWithRedirect() {
    this.phAuthServiceConfig.subscribe((config) => {
      const organization = config.AUTH_ORG_ID;
      const [ui_locales] = config.LOCALE.split('-');
      this.authSrv.loginWithRedirect({
        authorizationParams: { organization, ui_locales },
      });
    });
  }

  logout() {
    localStorage.removeItem('refreshToken');
    this.phAuthServiceConfig
      .pipe(map((config) => config.AUTH_LOGOUT_CALLBACK_URL))
      .subscribe((returnTo) => {
        this.authSrv.logout({ logoutParams: { federated: true, returnTo } });
      });
  }
}
