import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { EMPTY, Observable, ReplaySubject, of, timer } from 'rxjs';
import { catchError, first, mergeMap, mergeMapTo, tap } from 'rxjs/operators';
import {
  AuthFlows,
  PH_AUTH_SERVICE_CONFIG,
  PhAuthService,
  PhAuthServiceConfig,
} from '../ph-auth.service';

interface DeviceAuthConfig {
  device_code: string;
  expires_in: number;
  interval: number;
  user_code: string;
  verification_uri: string;
  verification_uri_complete: string;
}

interface AuthData {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
}

const SCOPES = [
  'access:display-board',
  'access:kiosk',
  'offline_access',
  'write:patients',
  'write:securable',
  'read:patients',
  'read:securable',
  'read:departments',
  'read:sites',
  'read:staff',
  'read:stations',
  'read:tags',
  'read:kiosks',
];
const scope = SCOPES.join(' ');
const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

@Injectable()
export class PhAuthDeviceService {
  loading$ = new ReplaySubject<boolean>(1);
  code$ = new ReplaySubject<string>(1);
  link$ = new ReplaySubject<string>(1);

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PH_AUTH_SERVICE_CONFIG)
    private phAuthServiceConfig: PhAuthServiceConfig,
    private phAuthSrv: PhAuthService,
    private router: Router
  ) {
    this.phAuthSrv.setAuthFlow(AuthFlows.DEVICE);
    this.phAuthSrv.whenAuthenticated$.subscribe(() =>
      this.router.navigate(['/'], { queryParamsHandling: 'preserve' })
    );
    this.loading$.next(true);
    of(localStorage.getItem('refreshToken'))
      .pipe(
        mergeMap((storedRefreshToken) =>
          !!storedRefreshToken
            ? this.initRefreshTokenAuthorizationFlow()
            : this.initDeviceAuthorizationFlow()
        )
      )
      .subscribe({ error: (err) => this.onAuthorizationFailure(err).subscribe() });
  }

  private refreshToken(): Observable<AuthData> {
    const refresh_token = localStorage.getItem('refreshToken');
    return this.phAuthServiceConfig.pipe(
      first(),
      mergeMap((config) => {
        const url = 'https://' + config.AUTH_DOMAIN + '/oauth/token';
        const body = new URLSearchParams({
          client_id: config.AUTH_DEVICE_CLIENT_ID,
          grant_type: 'refresh_token',
          scope,
          refresh_token,
        }).toString();
        return this.http.post<AuthData>(url, body, { headers });
      })
    );
  }

  private initDeviceAuthorizationFlow(): Observable<AuthData> {
    return this.getDeviceCode().pipe(
      tap((deviceAuthConfig) => this.showDeviceFlowScreen(deviceAuthConfig)),
      mergeMap((deviceAuthConfig) => this.onDeviceAuthorized(deviceAuthConfig)),
      mergeMap((authData) => this.onAuthorizationSuccess(authData)),
      catchError((err) => this.onAuthorizationFailure(err))
    );
  }

  private initRefreshTokenAuthorizationFlow(): Observable<AuthData> {
    return this.refreshToken().pipe(
      mergeMap((authData) => this.onAuthorizationSuccess(authData)),
      catchError((err) => {
        /**
         * This block should not be usually reached.
         * We only expect errors refreshing tokens if the internet connection fails or Auth0 is not available.
         */
        Sentry.captureException(err);
        return timer(5000).pipe(mergeMap(() => this.initRefreshTokenAuthorizationFlow()));
      })
    );
  }

  private getDeviceCode(): Observable<DeviceAuthConfig> {
    return this.phAuthServiceConfig.pipe(
      first(),
      mergeMap((config) => {
        const url = 'https://' + config.AUTH_DOMAIN + '/oauth/device/code';
        const body = new URLSearchParams({
          client_id: config.AUTH_DEVICE_CLIENT_ID,
          scope,
          audience: config.AUTH_AUDIENCE,
        }).toString();
        return this.http.post<DeviceAuthConfig>(url, body, { headers });
      })
    );
  }

  private showDeviceFlowScreen(data: DeviceAuthConfig): void {
    this.code$.next(data.user_code);
    this.link$.next(data.verification_uri_complete);
    this.loading$.next(false);
  }

  private onDeviceAuthorized(config: DeviceAuthConfig): Observable<AuthData> {
    const req = this.checkDeviceAuthorizationState(config).pipe(
      catchError((err) => {
        if (err?.error?.error === 'authorization_pending') {
          return this.onDeviceAuthorized(config);
        }
        throw err;
      })
    );
    return timer(config.interval * 1000).pipe(mergeMap(() => req));
  }

  private checkDeviceAuthorizationState(
    deviceAuthConfig: DeviceAuthConfig
  ): Observable<AuthData> {
    return this.phAuthServiceConfig.pipe(
      first(),
      mergeMap((config) => {
        const url = 'https://' + config.AUTH_DOMAIN + '/oauth/token';
        const body = new URLSearchParams({
          client_id: config.AUTH_DEVICE_CLIENT_ID,
          device_code: deviceAuthConfig.device_code,
          grant_type: 'urn:ietf:params:oauth:grant-type:device_code',
        }).toString();
        return this.http.post<AuthData>(url, body, { headers });
      })
    );
  }

  private onAuthorizationSuccess(data: AuthData): Observable<never> {
    return this.phAuthServiceConfig.pipe(
      first(),
      mergeMap((config) => {
        localStorage.setItem('refreshToken', data.refresh_token);
        this.phAuthSrv.setToken(data.access_token);
        const refreshIn = (data.expires_in - config.REFRESH_TOKEN_IN_ADVANCE_SEC) * 1000;
        return timer(refreshIn).pipe(
          mergeMap(() => this.refreshToken()),
          mergeMap((authData) => this.onAuthorizationSuccess(authData))
        );
      })
    );
  }

  private onAuthorizationFailure(err: any): Observable<never> {
    const BASE_ERROR = $localize`Authentication flow will restart automatically in 20 seconds.`;
    let message;
    if (err?.error?.error === 'access_denied') {
      message = $localize`The user or authorization server denied the transaction.`;
    } else if (err?.error?.error === 'expired_token') {
      message = $localize`The user has not authorized the device quickly enough.`;
    } else {
      message = $localize`There was an unexpected error.`;
      Sentry.captureException(err);
    }
    this.snackBar.open([message, BASE_ERROR].join(' '), $localize`Close`);
    return this.reload();
  }

  private reload(): Observable<never> {
    return timer(10 * 1000).pipe(
      tap(() => this.doc.location.reload()),
      mergeMapTo(EMPTY)
    );
  }
}
