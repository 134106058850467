import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { EMPTY, merge, ReplaySubject, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  groupBy,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';

interface CurrentUserDataQuery {
  currentUser: User;
}

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  preferences: { [key: string]: any };
}

const CurrentUserDataQuery = gql(`
query CurrentUserDataQuery {
  currentUser {
    id
    first_name
    last_name
    email
    preferences
  }
}
`);

@Injectable()
export class CurrentUserService {
  _user$: ReplaySubject<User> = new ReplaySubject(1);
  _newPreference$: Subject<[string, any]> = new Subject();
  preferences$: ReplaySubject<{ [key: string]: any }> = new ReplaySubject(1);

  constructor(private apollo: Apollo) {
    this.apollo
      .use('CA')
      .query<CurrentUserDataQuery>({ query: CurrentUserDataQuery, errorPolicy: 'all' })
      .pipe(map((result) => result.data.currentUser))
      .subscribe(this._user$);

    this._newPreference$
      .pipe(
        groupBy(([key]) => key),
        mergeMap((group) => group.pipe(debounceTime(3000)))
      )
      .subscribe(([key, val]) => this._storePermanently(key, val));

    const remotePreferences = this.user$.pipe(map((user) => user.preferences));

    const localPreferences = this._newPreference$.pipe(
      map(([key, value]) => ({ [key]: value })),
      withLatestFrom(this.preferences$),
      map(([newPref, existing]) => ({ ...existing, ...newPref }))
    );

    merge(remotePreferences, localPreferences).subscribe(this.preferences$);
  }

  get user$() {
    return this._user$.pipe(catchError(() => EMPTY));
  }

  getPreference(key: string) {
    return this.preferences$.pipe(map((x) => x[key]));
  }

  save(key: string, value: any) {
    this._newPreference$.next([key, value]);
  }

  _storePermanently(key: string, value?: any) {
    return this.apollo
      .use('CA')
      .mutate<JSON>({
        mutation: gql(`
        mutation($key: String!, $value: JSON) {
          updatePreferences(key: $key, value: $value)
        }
      `),
        variables: { key, value },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      })
      .subscribe();
  }
}
