import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getQueryParams } from '@frontend/common/ph-router-store';
import { ExpectedError, throwExpectedError } from '@frontend/common/util';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { first, map, mergeMap } from 'rxjs/operators';
import { LoadDisplayBoardConfig } from './display-board-config.actions';
import { DisplayBoardConfig } from './display-board-config.model';

const displayBoardConfigQuery = gql(`
subscription DisplayBoardConfig($displayBoardId: Int!) {
  display_board(where: {id: {_eq: $displayBoardId}}) {
    id
    name
    display_board_type_id
    workflow_ids
    phase_ids
    page_rotation_sec
    table_configuration
    department {
      site {
        default_timezone
      }
    }
    show_badge
    show_display_name
    show_estimated_duration
    show_avg_duration
    play_tone
    play_announcement
    location_ids
    display_style_id
  }
}
`);

@Injectable()
export class DisplayBoardConfigService {
  constructor(
    private apollo: Apollo,
    private store: Store,
    private snackBar: MatSnackBar
  ) {
    this.store
      .select(getQueryParams)
      .pipe(
        first(),
        map((queryParams) => {
          if (!queryParams.id) {
            throwExpectedError($localize`Missing ID in url.`);
          }
          return queryParams.id;
        }),
        mergeMap((displayBoardId) =>
          this.apollo
            .subscribe({
              query: displayBoardConfigQuery,
              variables: { displayBoardId },
            })
            .pipe(
              map((result: { data: { display_board: DisplayBoardConfig[] } }) => {
                if (!result.data.display_board.length) {
                  throwExpectedError($localize`Display board does not exist.`);
                }
                return result.data.display_board[0];
              })
            )
        )
      )
      .subscribe(
        (config) => this.store.dispatch(new LoadDisplayBoardConfig(config)),
        (err: ExpectedError | Error) => {
          const unexpected = $localize`There was an unexpected error.`;
          const expected =
            typeof err === 'object' && (err as ExpectedError)?.expectedError;
          const message = expected || unexpected;
          this.snackBar.open(message);
          if (!expected) {
            throw err;
          }
          console.warn(err.stack);
        }
      );
  }
}
