import { ProductId } from './product-id';

export const ProductTranslation: { [key in ProductId]: string } = {
  [ProductId.CentralAdmin]: $localize`Central Admin`,
  [ProductId.Workflow]: $localize`Workflowᴿᵀ`,
  [ProductId.DisplayBoards]: $localize`Screensᴿᵀ`,
  [ProductId.Kiosks]: $localize`Kiosksᴿᵀ`,
  [ProductId.Assets]: $localize`Assetsᴿᵀ`,
  [ProductId.Pulse]: $localize`Pulseᴿᵀ`,
  [ProductId.Connect]: $localize`Connectᴿᵀ`,
  [ProductId.HandHygiene]: $localize`Hygieneᴿᵀ`,
  [ProductId.MapManager]: $localize`Mapsᴿᵀ`,
  [ProductId.Duress]: $localize`Duressᴿᵀ`,
};
