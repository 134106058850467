<div
  *ngIf="!(phAuthDeviceSrv.loading$ | async)"
  style="
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2f8fe;
  "
>
  <img
    alt="Prompt Health"
    i18n-alt
    src="assets/prompt_health.svg"
    style="height: 80px; margin-bottom: 40px"
  />
  <div
    class="mat-typography"
    style="
      font-size: 46px;
      padding: 0 40px;
      max-width: 800px;
      line-height: 64px;
      text-align: center;
      color: rgba(0, 0, 0, 0.54);
    "
  >
    <span class="h1" i18n>
      To activate this device use the following code:
      <b style="white-space: nowrap">{{ phAuthDeviceSrv.code$ | async }}</b>
    </span>
    <div style="position: absolute; top: 0; left: 0">
      <a [href]="phAuthDeviceSrv.link$ | async" target="_blank" style="color: transparent"
        >Link</a
      >
    </div>
  </div>
</div>
