import * as Sentry from '@sentry/angular';

export function setupSentry(dsn, url, version, appName) {
  const customer = parseCustomer(url);
  const environment = parseEnvironment(url);
  const branch = parseBranch(version);
  const shortSemVer = parseShortSemVer(version);
  const release = getReleaseName(shortSemVer, appName);
  Sentry.init({ dsn, environment, release });
  Sentry.setTags({ customer, branch });
}

export function parseBranch(version: string): string {
  return version.split('-')?.[1]?.split('.')?.[0] ?? null;
}

export function parseShortSemVer(version: string): string {
  const regexp = /(\d+)\.(\d+)\.(\d+)/g;
  return version.match(regexp)?.join('.') ?? null;
}

export function parseCustomer(url: string): string {
  return url.split('.')?.[0]?.split('//')?.[1] ?? null;
}

export function parseEnvironment(url: string): string {
  const env = url.split('.')?.slice(-3)?.join('.')?.split('/')?.[0];
  if (env.indexOf('prompthealth.io') > -1) {
    return 'Production';
  }
  switch (env) {
    case 'dev.prompthealth-test.net':
      return 'Development';
    case 'alpha.prompthealth-test.net':
      return 'Alpha';
    case 'demo.prompthealth-test.net':
      return 'Demo';
    case 'staging.infiniteleap.net':
      return 'Preprod';
    default:
      return null;
  }
}

export function getReleaseName(semver: string, app: string): string {
  return semver && app ? [semver, '@', app].join('') : null;
}
