import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { UpdaterConfig } from './updater-config';
import { CheckForUpdateService } from './updater.service';

@NgModule({
  imports: [CommonModule],
  providers: [CheckForUpdateService],
})
export class UpdaterModule {
  constructor(@Optional() @SkipSelf() parentModule?: UpdaterModule) {
    if (parentModule) {
      throw new Error('UpdaterModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(deps, factory): ModuleWithProviders<UpdaterModule> {
    return {
      ngModule: UpdaterModule,
      providers: [{ provide: UpdaterConfig, useFactory: factory, deps }],
    };
  }
}
