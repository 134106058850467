export const INTERNALS = {
  workflowName: '[workflow_name]',
  phaseName: '[phase_name]',
  supplementaryAnnouncement: '[supplementary_announcement]',
  stationName: '[station_name]',
  tagLabel: '[tag_label]',
  badgeLabel: '[badge_label]',
  patientFirstName: '[patient_first_name]',
  date: '[date]',
  time: '[time]',
  organizationAlias: '[organization_alias]',
};
