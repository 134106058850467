import { Component } from '@angular/core';
import { PhAuthService } from '@frontend/common/ph-auth';
import { CheckForUpdateService } from '@frontend/common/updater';
import * as Sentry from '@sentry/angular';
import { AppLoadingService } from './app-loading.service';

@Component({
  selector: 'db-root',
  templateUrl: './app.component.html',
  providers: [AppLoadingService],
})
export class AppComponent {
  constructor(
    protected updater: CheckForUpdateService,
    public appLoadingSrv: AppLoadingService,
    private phAuthSrv: PhAuthService
  ) {
    this.phAuthSrv.user$.subscribe((user) => {
      if (user?.sub) {
        Sentry.configureScope((scope) => {
          scope.setUser({ id: user.sub });
        });
      }
    });
  }
}
