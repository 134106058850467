export const StaffTypeTranslation: { [key: number]: string } = {
  1: $localize`Admin`,
  2: $localize`Provider`,
  3: $localize`Nurse`,
  4: $localize`Assesstants`,
  5: $localize`Environmental Services`,
  6: $localize`Other`,
  7: $localize`Scheduler`,
  8: $localize`Registrar`,
  9: $localize`Advanced Practice Practitioner (APP)`,
  10: $localize`Anesthesiologist`,
  11: $localize`Nurse Practitioner (NP)`,
  12: $localize`Physician (MD)`,
  13: $localize`Physician Assistant (PA)`,
  14: $localize`Provider`,
  15: $localize`Surgeon`,
  16: $localize`Licensed Practical Nurse (LPN)`,
  17: $localize`Nurse`,
  18: $localize`Registered Nurse (RN)`,
  19: $localize`Certified Nursing Assistant (CNA)`,
  20: $localize`Medical Assistant (MA)`,
  21: $localize`Medical Office Specialist (MOS)`,
  22: $localize`EVS`,
  23: $localize`Dietician`,
  24: $localize`General`,
  25: $localize`Lab`,
  26: $localize`Pharmacist`,
  27: $localize`Respiratory Therapist (RT)`,
  28: $localize`Social Worker`,
  29: $localize`Technician`,
  30: $localize`Technologist`,
  31: $localize`Radiologist`,
  32: $localize`Therapist`,
  33: $localize`Ear Nose and Throat Doctor (ENT)`,
  34: $localize`Fellow/Resident`,
  35: $localize`Unit Secretary (US)`,
  36: $localize`Certified Registred Nurse Anesthetist (CRNA)`,
  37: $localize`Sonographer`,
  38: $localize`Security`,
  39: $localize`OR Assistant`,
  40: $localize`Radiology Technician (Rad Tech)`,
  41: $localize`Surgery Technician (Surge Tech)`,
  42: $localize`Patient Care Technician`,
  43: $localize`Cath Lab Technician`,
  44: $localize`Research`,
  45: $localize`Infusion Nurse`,
  46: $localize`Clinical Oncology Associate (COA)`,
  47: $localize`Leadership`,
  48: $localize`Apheresis Nurse`,
  49: $localize`Patient Encounter Associate (PEA)`,
  50: $localize`Speech Language Pathologist`,
};
