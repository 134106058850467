import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PhAuthService } from '../ph-auth.service';

@Injectable()
export class PhAuthSdkService {
  constructor(
    private phAuthSrv: PhAuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.phAuthSrv.whenAuthenticated$.subscribe(() => {
      const next = this.activatedRoute.snapshot.queryParams.next;
      this.router.navigateByUrl(next);
    });
    this.getNewToken();
    this.phAuthSrv.requestUpdate$.subscribe(() => this.getNewToken());
  }

  getNewToken() {
    this.phAuthSrv.getAccessTokenSilently().subscribe(
      (token) => this.phAuthSrv.setToken(token),
      (err) => {
        console.warn('Was not possible to get token silently:', err);
        console.log('Redirecting to login...');
        this.phAuthSrv.loginWithRedirect();
      }
    );
  }
}
