import { Injectable, Optional } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { timer } from 'rxjs';
import { UpdaterConfig } from './updater-config';

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {
  constructor(@Optional() config: UpdaterConfig, updates: SwUpdate) {
    if (!updates.isEnabled) {
      console.warn('ServiceWorker (thus auto-update) is disabled.');
      return;
    }

    const checkIntervalMs = config.checkInterval * 1000;
    timer(0, checkIntervalMs).subscribe(() => updates.checkForUpdate());

    updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          console.log('Automatically activating new version.', JSON.stringify(evt));
          updates.activateUpdate().then(() => document.location.reload());
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(
            `Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
      }
    });

    updates.unrecoverable.subscribe((event) => {
      document.location.reload();
      throw new Error(`An error occurred that we cannot recover from: ${event.reason}`);
    });
  }
}
