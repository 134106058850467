import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { AuthModule } from '@auth0/auth0-angular';
import { DeviceComponent } from './auth-flow-device/device.component';
import { SdkCallbackComponent } from './auth-flow-sdk/sdk-callback.component';
import { SdkComponent } from './auth-flow-sdk/sdk.component';
import { PhAuthLogoutComponent } from './ph-auth-logout.component';
import { PhAuthGuard } from './ph-auth.guard';
import { PhAuthService } from './ph-auth.service';
import { ScopesGuard } from './scopes.guard';

@NgModule({
  imports: [CommonModule, RouterModule, MatSnackBarModule, AuthModule.forRoot()],
  providers: [PhAuthService, PhAuthGuard, ScopesGuard],
  declarations: [
    DeviceComponent,
    SdkComponent,
    SdkCallbackComponent,
    PhAuthLogoutComponent,
  ],
})
export class PhAuthModule {}
