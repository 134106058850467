import { Component } from '@angular/core';
import { PhAuthSdkService } from './sdk.service';

@Component({
  selector: 'ph-auth-flow-sdk-component',
  template: '',
  providers: [PhAuthSdkService],
})
export class SdkComponent {
  constructor(public phAuthSdkSrv: PhAuthSdkService) {}
}
