import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, shareReplay, skipUntil } from 'rxjs/operators';
import { getConfig, getConfigLoaded } from './display-board-config.selectors';
import { DISPLAY_BOARD_ROUTES } from './display-board-routes';

@Injectable({ providedIn: 'root' })
export class DisplayBoardExpectedRouteService {
  private _expectedRoute$ = this.store.select(getConfig).pipe(
    skipUntil(this.store.select(getConfigLoaded).pipe(filter((loaded) => !!loaded))),
    map((config) => DISPLAY_BOARD_ROUTES[config.display_board_type_id]),
    shareReplay(1)
  );

  constructor(private store: Store) {}

  get expectedRoute$() {
    return this._expectedRoute$;
  }
}
