import { Component } from '@angular/core';
import { PhAuthDeviceService } from './device.service';

@Component({
  selector: 'ph-auth-flow-device-component',
  templateUrl: './device.component.html',
  providers: [PhAuthDeviceService],
})
export class DeviceComponent {
  constructor(public phAuthDeviceSrv: PhAuthDeviceService) {}
}
