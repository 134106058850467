import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDisplayBoardConfig from './display-board-config.reducer';

const getState = createFeatureSelector<fromDisplayBoardConfig.State>(
  fromDisplayBoardConfig.featureKey
);

export const getConfig = createSelector(getState, (state) => state.config);

export const getConfigLoaded = createSelector(getState, (state) => !!state.config);
