import { Injectable } from '@angular/core';
import { PhAuthService } from '@frontend/common/ph-auth';
import { PhConfigLoaderService } from '@frontend/common/ph-config-loader';
import { Apollo } from 'apollo-angular';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { combineLatest } from 'rxjs';
import { filter, first, mapTo } from 'rxjs/operators';

@Injectable()
export class GraphQlService {
  private token: string;
  private uri$ = this.config.getConfig$('GRAPHQL_URL').pipe(filter((uri) => !!uri));
  private token$ = this.phAuthSrv.token$.pipe(filter((token) => !!token));
  private both$ = combineLatest([this.uri$, this.token$]).pipe(first());

  isReady$ = this.both$.pipe(mapTo(true));

  constructor(
    private apollo: Apollo,
    private config: PhConfigLoaderService,
    private phAuthSrv: PhAuthService
  ) {
    this.token$.subscribe((token) => (this.token = token));
    this.both$.subscribe(([uri]) => {
      const link = new WebSocketLink({
        uri,
        options: {
          reconnect: true,
          connectionParams: () => ({
            headers: { Authorization: `Bearer ${this.token}` },
          }),
        },
      });

      const cache = new InMemoryCache();

      this.apollo.create({ link, cache });
    });
  }
}
