import { Component } from '@angular/core';
import { PhAuthService } from './ph-auth.service';

@Component({
  selector: 'ph-auth-logout-component',
  template: '',
})
export class PhAuthLogoutComponent {
  constructor(private phAuthSrv: PhAuthService) {
    this.phAuthSrv.logout();
  }
}
