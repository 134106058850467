import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Params,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AUTH_FLOW_DEVICE_PATH_LOGIN, AUTH_FLOW_SDK_PATH_LOGIN } from './ph-auth-routes';
import { AuthFlows, PhAuthService } from './ph-auth.service';

@Injectable()
export class PhAuthGuard implements CanActivate {
  constructor(private phAuthSrv: PhAuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.phAuthSrv.isAuthenticated$.pipe(
      tap(
        (isAuthenticated) =>
          isAuthenticated || this.navigateToLogin(state.url, route.queryParams)
      )
    );
  }

  navigateToLogin(next: string, allQueryParams: Params) {
    const { auth_flow, ...queryParams } = allQueryParams;
    switch (auth_flow) {
      case AuthFlows.DEVICE:
        this.router.navigate([AUTH_FLOW_DEVICE_PATH_LOGIN], {
          queryParams,
          queryParamsHandling: 'merge',
        });
        break;
      default:
        this.router.navigate([AUTH_FLOW_SDK_PATH_LOGIN], {
          queryParams: next !== '/' ? { next } : undefined,
          queryParamsHandling: 'merge',
        });
        break;
    }
  }
}
