import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { PhAuthService } from './ph-auth.service';

const NO_ACCESS_MSG = $localize`You do not have permissions to access this section.`;

@Injectable()
export class ScopesGuard implements CanActivate {
  constructor(private phAuthSrv: PhAuthService, private snackBar: MatSnackBar) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const required = route.data.requiredScopes;
    return this.phAuthSrv.scopes$.pipe(
      first(),
      map((scopes) => !required || required.every((s) => scopes.includes(s))),
      tap((access) => {
        if (!access) {
          this.snackBar.open(NO_ACCESS_MSG, $localize`Close`);
        }
      })
    );
  }
}
