export const PERMISSIONS: string = [
  // UI
  'access:ca',
  'access:display-board',
  'access:kiosk',
  'access:mm',
  'access:wrt',
  'ui:wrt:contact-tracing',
  'ui:wrt:recipients-chat',
  'ui:wrt:reports',
  'ui:wrt:rooms',
  'ui:wrt:staff',
  'ui:wrt:staff-chat',
  'ui:ca:organization',
  'ui:ca:organization-create',
  'ui:ca:organization-crm',
  'ui:ca:organization-licensing',
  'ui:ca:organization-settings-sms-service',
  'ui:ca:organization-settings-email-service',
  'ui:ca:organization-settings-data-warehouse',
  'ui:ca:organization-settings-license-info',
  'ui:ca:organization-settings-theme',
  'ui:ca:users',
  'ui:ca:sites',
  'ui:ca:site-departments',
  'ui:ca:site-sensors',
  'ui:ca:site-locations',
  'ui:ca:site-devices',
  'ui:ca:site-groups',
  'ui:ca:site-hierarchy',
  'ui:ca:tags',
  'ui:ca:staff',
  'ui:ca:connect',
  'ui:ca:wayfinding',
  'ui:ca:workflow',
  'ui:ca:wrt-departments',
  'ui:ca:wrt-departments-manage',
  'ui:ca:wrt-workflows',
  'ui:ca:wrt-phases',
  'ui:ca:wrt-display-boards',
  'ui:ca:wrt-alerts',
  'ui:ca:wrt-alerts-manage',
  'ui:ca:wrt-quick-messages',
  'ui:ca:wrt-stations',
  'ui:ca:wrt-kiosks',
  'ui:ca:wrt-appointment-types',
  // API
  'create-bulk:appointment-types',
  'create-bulk:assets',
  'create-bulk:locations',
  'create-bulk:sensors',
  'create-bulk:staff',
  'create-bulk:tags',
  'create-bulk:users',
  'create:customer',
  'read:appointment-types',
  'read:assets',
  'read:contact-tracing',
  'read:crm',
  'read:customer',
  'read:departments',
  'read:display-boards',
  'read:kiosks',
  'read:locations',
  'read:milestone-users',
  'read:notifications',
  'read:notifications-subscribers',
  'read:patients',
  'read:phases',
  'read:quick-messages',
  'read:recipients',
  'read:reports',
  'read:securable',
  'read:sensors',
  'read:sites',
  'read:staff',
  'read:stations',
  'read:tags',
  'read:users',
  'read:utypes',
  'read:wayfinding',
  'read:workflow-departments',
  'read:workflow-staff',
  'read:workflow-workflows',
  'read:workflows',
  'toggle:customer',
  'update:customer',
  'update:customer-advance',
  'update:customer-data-warehouse',
  'update:customer-twilio',
  'write:admins',
  'write:appointment-types',
  'write:assets',
  'write:departments',
  'write:display-boards',
  'write:kiosks',
  'write:locations',
  'write:map-stage',
  'write:notifications',
  'write:owners',
  'write:patients',
  'write:phases',
  'write:quick-messages',
  'write:recipients',
  'write:securable',
  'write:sensors',
  'write:sites',
  'write:staff',
  'write:stations',
  'write:tags',
  'write:users',
  'write:workflow-departments',
  'write:workflows',
].join(' ');
