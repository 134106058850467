import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { CorrelationIdModule } from '@frontend/common/correlation-id';
import { InterceptorModule } from '@frontend/common/interceptor';
import { LoadingModule } from '@frontend/common/loading';
import {
  PhAuthModule,
  PhAuthServiceConfig,
  PH_AUTH_SERVICE_CONFIG,
} from '@frontend/common/ph-auth';
import {
  PhConfigLoaderModel,
  PhConfigLoaderModule,
  PhConfigLoaderService,
} from '@frontend/common/ph-config-loader';
import {
  CustomSerializer,
  RouterEffects,
  routerReducerInitialState,
  RouterStateUrl,
} from '@frontend/common/ph-router-store';
import { UpdaterConfig, UpdaterModule } from '@frontend/common/updater';
import { setupSentry } from '@frontend/common/util';
import { EffectsModule } from '@ngrx/effects';
import * as fromRouter from '@ngrx/router-store';
import {
  DefaultRouterStateSerializer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NxModule } from '@nrwl/angular';
import * as Sentry from '@sentry/angular';
import { ApolloModule } from 'apollo-angular';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DisplayBoardGuard } from './display-board-config.guard';
import * as fromDisplayBoardConfig from './display-board-config.reducer';
import { DisplayBoardConfigService } from './display-board-config.service';
import { DisplayBoardExpectedRouteService } from './display-board-expected-route.service';
import { DisplayBoardTypeRedirectService } from './display-board-type-redirect.service';
import { GraphQlGuard } from './graphql.guard';
import { GraphQlService } from './graphql.service';

@Injectable()
export class PhConfigLoaderFactory {
  constructor(private http: HttpClient) {}
  cb = (config: PhConfigLoaderModel): Observable<PhConfigLoaderModel> =>
    this.http.get(config.APP_API_URL + 'customers/id').pipe(
      map(
        (customer: {
          id: number;
          auth_org_id: string;
          auth_device_client_id: string;
          locale: string;
        }) => customer
      ),
      catchError(() =>
        of({
          id: 'disabled',
          auth_org_id: null,
          auth_device_client_id: null,
          locale: null,
        })
      ),
      map((customer) => ({
        ...config,
        CUSTOMER_ID: customer.id,
        AUTH_ORG_ID: customer.auth_org_id,
        AUTH_DEVICE_CLIENT_ID: customer.auth_device_client_id,
        LOCALE: customer.locale,
      })),
      tap(({ SENTRY_DSN }) => {
        if (environment.production) {
          setupSentry(
            SENTRY_DSN,
            window.location.href,
            environment.version,
            'display-board'
          );
        }
      })
    );
}

export interface State {
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
  displayBoardConfig: fromDisplayBoardConfig.State;
}

export const reducers: ActionReducerMap<State> = {
  routerReducer: fromRouter.routerReducer,
  [fromDisplayBoardConfig.featureKey]: fromDisplayBoardConfig.reducer,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NxModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers: [],
      initialState: { routerReducer: routerReducerInitialState },
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
    }),
    EffectsModule.forRoot([RouterEffects]),
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
    PhConfigLoaderModule.forRoot(PhConfigLoaderFactory),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 100 }),
    PhAuthModule,
    CorrelationIdModule, // should not be required by InterceptorModule
    InterceptorModule,
    ApolloModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    UpdaterModule.forRoot(
      [PhConfigLoaderService],
      (appConfig: PhConfigLoaderService): UpdaterConfig => ({
        checkInterval: appConfig.getConfig('CHECK_RELEASE_AFTER_SEC'),
      })
    ),
    LoadingModule,
  ],
  providers: [
    GraphQlGuard,
    GraphQlService,
    {
      provide: APP_INITIALIZER,
      deps: [PhConfigLoaderService, AuthClientConfig],
      useFactory: (ac: PhConfigLoaderService) => () => ac.load(),
      multi: true,
    },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    {
      provide: ErrorHandler,
      useFactory: () => {
        if (environment.production) {
          return Sentry.createErrorHandler();
        }
        return new ErrorHandler();
      },
    },
    {
      provide: PH_AUTH_SERVICE_CONFIG,
      useFactory: phAuthServiceFactory,
      deps: [PhConfigLoaderService],
    },
    DisplayBoardGuard,
    DisplayBoardConfigService,
    DisplayBoardTypeRedirectService,
    DisplayBoardExpectedRouteService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

function phAuthServiceFactory(configSrv: PhConfigLoaderService): PhAuthServiceConfig {
  return combineLatest([
    configSrv.getConfig$('APP_API_URL'),
    configSrv.getConfig$('AUTH_DOMAIN'),
    configSrv.getConfig$('AUTH_AUDIENCE'),
    configSrv.getConfig$('AUTH_DEVICE_CLIENT_ID'),
    configSrv.getConfig$('REFRESH_TOKEN_IN_ADVANCE_SEC'),
    configSrv.getConfig$('AUTH_LOGOUT_CALLBACK_URL'),
    configSrv.getConfig$('AUTH_ORG_ID'),
    configSrv.getConfig$('LOCALE'),
  ]).pipe(
    map(
      ([
        APP_API_URL,
        AUTH_DOMAIN,
        AUTH_AUDIENCE,
        AUTH_DEVICE_CLIENT_ID,
        REFRESH_TOKEN_IN_ADVANCE_SEC,
        AUTH_LOGOUT_CALLBACK_URL,
        AUTH_ORG_ID,
        LOCALE,
      ]) => ({
        APP_API_URL,
        AUTH_DOMAIN,
        AUTH_AUDIENCE,
        AUTH_DEVICE_CLIENT_ID,
        REFRESH_TOKEN_IN_ADVANCE_SEC,
        AUTH_LOGOUT_CALLBACK_URL,
        AUTH_ORG_ID,
        LOCALE,
      })
    )
  );
}
