import {
  AUTH_FLOW_SDK_PATH_LOGIN,
  AUTH_FLOW_SDK_PATH_LOGIN_CALLBACK,
} from '../ph-auth-routes';
import { SdkCallbackComponent } from './sdk-callback.component';
import { SdkComponent } from './sdk.component';

export const AUTH_FLOW_SDK_ROUTES = [
  { path: AUTH_FLOW_SDK_PATH_LOGIN, component: SdkComponent },
  { path: AUTH_FLOW_SDK_PATH_LOGIN_CALLBACK, component: SdkCallbackComponent },
];
