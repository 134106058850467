import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { clearCache, updateCache } from './actions';
import { Securable } from './model';

export const featureKey = 'securable';

export const adapter: EntityAdapter<Securable> = createEntityAdapter<Securable>();

export interface State extends EntityState<Securable> {}

export const initialState: State = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(updateCache, (state, { securables }) => {
    return adapter.addMany(securables, state);
  }),
  on(clearCache, () => initialState)
);

const { selectIds, selectEntities } = adapter.getSelectors();

export const getState = createFeatureSelector<State>(featureKey);
export const getIds = createSelector(getState, selectIds);
export const getEntities = createSelector(getState, selectEntities);
