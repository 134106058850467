import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

export interface UserNotificationDataQuery {
  userNotification: UserNotification;
}

export interface UserNotification {
  id: number;
  notification_title: string;
  notification_message: string;
}

export const UserNotificationDataQuery = gql(`
query UserNotificationDataQuery {
  userNotification {
    id
    notification_title
    notification_message
  }
}
`);

@Injectable()
export class UserNotificationService {
  _data$: ReplaySubject<UserNotification> = new ReplaySubject(1);

  constructor(private apollo: Apollo) {
    this._getData().subscribe(this._data$);
  }

  get notification$() {
    return this._data$.pipe(
      catchError(() => EMPTY),
      filter((data) => !!data)
    );
  }

  _getData(): Observable<UserNotification> {
    return this.apollo
      .use('CA')
      .query<UserNotificationDataQuery>({
        query: UserNotificationDataQuery,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result) => {
          if (!result.data) {
            throw new Error('Error getting user notifications (result.data was falsy)');
          }
          return result.data.userNotification;
        })
      );
  }
}
