import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { EMPTY, Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

export interface DepartmentDataQuery {
  department: Department[];
}

export interface Department {
  id: number;
  name: string;
  site: {
    id: number;
    name: string;
    timezone: {
      id: string;
    };
  };
}

export const DepartmentDataQuery = gql(`
subscription DepartmentDataQuery($id: Int!) {
  department(where: { id: { _eq: $id } }) {
    id
    name
    site {
      id
      name
      timezone {
        id
      }
    }
  }
}
`);

@Injectable()
export class CurrentDepartmentService {
  _requestedId$: Subject<string> = new Subject();
  departmentRaw$: ReplaySubject<Department> = new ReplaySubject(1);

  constructor(private apollo: Apollo, private snackBar: MatSnackBar) {
    this._requestedId$
      .pipe(switchMap((id) => this._getDepartment(id)))
      .subscribe(this.departmentRaw$);

    this.departmentRaw$.subscribe({
      error: (err) => {
        const message = $localize`There was an unexpected error.`;
        this.snackBar.open(message, $localize`Close`, { duration: 10000 });
        throw err;
      },
    });
  }

  get department$() {
    return this.departmentRaw$.pipe(catchError(() => EMPTY));
  }

  load(id: string): void {
    this._requestedId$.next(id);
  }

  _getDepartment(id: string): Observable<Department> {
    return this.apollo
      .query<DepartmentDataQuery>({
        query: DepartmentDataQuery,
        variables: { id },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result) => {
          if (!result.data) {
            throw new Error('Error getting department (result.data was falsy)');
          }
          return result.data.department[0];
        })
      );
  }
}
