import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { AUTH_FLOW_SDK_PATH_LOGIN_CALLBACK, PERMISSIONS } from '@frontend/common/ph-auth';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { PhConfigLoaderFactoryModel } from './ph-config-loader-factory.model';

@Injectable()
export class PhConfigLoaderService {
  private c: any = null;
  private config$: Subject<{ [key: string]: any }> = new BehaviorSubject({});
  configFactory: PhConfigLoaderFactoryModel;

  constructor(
    private http: HttpClient,
    @Optional() configFactory: PhConfigLoaderFactoryModel,
    private authClientConfig: AuthClientConfig
  ) {
    this.configFactory = { ...configFactory };
  }

  // TODO: Migrate calls to getConfig$ and remove.
  getConfig(key: any) {
    return this.c[key];
  }

  getConfig$(key: any) {
    return this.config$.pipe(
      map((c) => c[key]),
      filter((val) => val !== undefined)
    );
  }

  load(): Promise<any> {
    const getConfig = this.http.get('config.json', {
      params: { 'cache-bust': Math.random() },
    });
    return new Promise<void>((resolve) => {
      getConfig
        .pipe(
          switchMap((config: PhConfigLoaderFactoryModel) =>
            this.configFactory.cb ? this.configFactory.cb(config) : of(config)
          )
        )
        .subscribe((config) => {
          const { protocol, hostname } = document.location;
          const { port } = location;
          const url = `${protocol}//${hostname + (port ? ':' + port : '')}/`;
          this.c = config;
          this.c.AUTH_LOGIN_CALLBACK_URL = url + AUTH_FLOW_SDK_PATH_LOGIN_CALLBACK;
          this.c.AUTH_LOGOUT_CALLBACK_URL = url;
          this.authClientConfig.set({
            domain: config.AUTH_DOMAIN,
            clientId: config.AUTH_CLIENT_ID,
            authorizationParams: {
              audience: config.AUTH_AUDIENCE,
              redirect_uri: config.AUTH_LOGIN_CALLBACK_URL,
              response_type: 'token id_token',
              scope: `openid profile ${PERMISSIONS}`,
            },
          });
          this.config$.next(this.c);
          resolve();
        });
    });
  }
}
