import { Action } from '@ngrx/store';
import { DisplayBoardConfig } from './display-board-config.model';

export enum DisplayBoardConfigActionTypes {
  Load = '[Display Board Config] Load',
}

export class LoadDisplayBoardConfig implements Action {
  readonly type = DisplayBoardConfigActionTypes.Load;
  constructor(public payload: DisplayBoardConfig) {}
}

export type DisplayBoardConfigActions = LoadDisplayBoardConfig;
