export const StaffTypeAliasTranslation: { [key: number]: string } = {
  9: $localize`APP`,
  11: $localize`NP`,
  12: $localize`MD`,
  13: $localize`PA`,
  16: $localize`LPN`,
  18: $localize`RN`,
  19: $localize`CNA`,
  20: $localize`MA`,
  21: $localize`MOS`,
  24: $localize`Staff`,
  27: $localize`RT`,
  33: $localize`ENT`,
  35: $localize`US`,
  36: $localize`CRNA`,
  40: $localize`Rad Tech`,
  41: $localize`Surge Tech`,
  46: $localize`COA`,
  49: $localize`PEA`,
};
