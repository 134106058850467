import { Injectable } from '@angular/core';

@Injectable()
export class CorrelationIdService {
  private correlations = new Map<string, any>();

  public add(correlationId: string, payload: any) {
    this.correlations.set(correlationId, payload);
  }

  public clear(correlationId: string) {
    this.correlations.delete(correlationId);
  }

  public getPayload(correlationId: string) {
    return this.correlations.get(correlationId);
  }

  public has(correlationId: string) {
    return !!this.correlations.get(correlationId);
  }
}
