export const TimezoneTranslation: { [key: string]: string } = {
  'America/Anchorage': $localize`America/Anchorage`,
  'America/Chicago': $localize`America/Chicago`,
  'America/Denver': $localize`America/Denver`,
  'America/Los_Angeles': $localize`America/Los Angeles`,
  'America/New_York': $localize`America/New York`,
  'America/Phoenix': $localize`America/Phoenix`,
  'Pacific/Honolulu': $localize`Pacific/Honolulu`,
  'Europe/Berlin': $localize`Europe/Berlin`,
  'America/North_Dakota/Center': $localize`America/North_Dakota/Center`,
};
