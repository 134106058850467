import { Injectable } from '@angular/core';
import { CorrelationIdService } from '@frontend/common/correlation-id';
import { PhAuthService } from '@frontend/common/ph-auth';
import { PhConfigLoaderService } from '@frontend/common/ph-config-loader';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { errorProcessor } from './error-processing';
import { WrappedSocketIo } from './socket-io/socket-io.service';

@Injectable({ providedIn: 'root' })
export class SocketService {
  private currentConnection: WrappedSocketIo;
  private connection$: ReplaySubject<WrappedSocketIo> = new ReplaySubject(1);

  constructor(
    private readonly correlationIdService: CorrelationIdService,
    private readonly phAuthSrv: PhAuthService,
    private readonly config: PhConfigLoaderService
  ) {
    combineLatest([
      this.config.getConfig$('SOCKETS_GATEWAY_URL').pipe(filter((url) => !!url)),
      this.config.getConfig$('CUSTOMER_ID').pipe(filter((id) => !!id)),
      this.phAuthSrv.token$.pipe(filter((token) => !!token)),
    ]).subscribe(([url, customerId, token]) => {
      if (this.currentConnection) {
        this.currentConnection.disconnect();
      }
      this.currentConnection = new WrappedSocketIo({
        url: url + customerId,
        options: { query: `token=${token}` },
      });
      this.currentConnection.on('connect', () => {
        this.currentConnection.emit('refreshToken', token);
      });
      this.connection$.next(this.currentConnection);
    });
  }

  fromEvent(event: string) {
    return this.connection$.pipe(mergeMap((conn) => conn.fromEvent(event)));
  }

  fromLocal(event: string) {
    return this.connection$.pipe(
      mergeMap((conn) =>
        conn.fromEvent(event).pipe(
          filter((wsEvent: { correlation_id: string }) =>
            this.correlationIdService.has(wsEvent.correlation_id)
          ),
          tap((wsEvent: { correlation_id: string }) => {
            setTimeout(() => this.correlationIdService.clear(wsEvent.correlation_id), 0);
          })
        )
      )
    );
  }

  fromLocalSuccess<T>(event: string): Observable<T> {
    return this.connection$.pipe(
      mergeMap((conn) =>
        conn.fromEvent<{ correlation_id: string }>(`${event}.success`).pipe(
          filter((wsEvent) => this.correlationIdService.has(wsEvent.correlation_id)),
          map((wsEvent) => {
            const initialPayload = this.correlationIdService.getPayload(
              wsEvent.correlation_id
            );
            return { ...initialPayload, ...wsEvent };
          }),
          tap((wsEvent) => {
            setTimeout(
              () => this.correlationIdService.clear(wsEvent.correlation_id),
              5000
            );
          })
        )
      )
    );
  }

  fromLocalError(event: string) {
    return this.connection$.pipe(
      mergeMap((conn) =>
        conn.fromEvent(`${event}.error`).pipe(
          filter((wsEvent: { correlation_id: string }) =>
            this.correlationIdService.has(wsEvent.correlation_id)
          ),
          map((ws) => errorProcessor.process(ws)),
          tap((wsEvent: any) => {
            setTimeout(() => this.correlationIdService.clear(wsEvent.correlation_id), 0);
          })
        )
      )
    );
  }

  fromGlobalSuccess(event: string) {
    return this.connection$.pipe(
      mergeMap((conn) =>
        conn
          .fromEvent(`${event}.success`)
          .pipe(
            filter(
              (wsEvent: { correlation_id: string }) =>
                !this.correlationIdService.has(wsEvent.correlation_id)
            )
          )
      )
    );
  }

  onEvent<T>(event: string, correlation_id?: string): Observable<T> {
    return this.connection$.pipe(
      mergeMap((conn) =>
        conn
          .fromEvent<T & { correlation_id: string }>(event)
          .pipe(
            filter(
              (wsEvent) => !correlation_id || wsEvent.correlation_id === correlation_id
            )
          )
      )
    );
  }
}
