export const AlertTypeTranslation: { [key: number]: string } = {
  1: $localize`Patient enters location`,
  2: $localize`Patient leaves location`,
  3: $localize`Patient max time in location`,
  4: $localize`Staff enters location`,
  5: $localize`Staff leaves location`,
  6: $localize`Staff max time in location`,
  7: $localize`Staff assist`,
  8: $localize`Patient enters phase`,
  9: $localize`Patient badge drop reminder`,
  10: $localize`Patient max time unattended`,
};
